import { getClients } from '@/API/clientAPI';
import { getPosts } from '@/API/PostsAPI';
import { getSeo } from '@/API/SeoAPI';
import { getAllServices, getTechnologyExpertise } from '@/API/serviceApi';
import { getStartUp } from '@/API/startUpAPI';
import { getAllSuccessStories } from '@/API/sucessStoriesAPI';
import { getTestimonials } from '@/API/testimonialAPI';
import { achievements } from '@/constants/data';
import { useContact } from '@/hooks/useContact';
import {
  Achievements,
  Blogs,
  Clients,
  ContactForm,
  HeaderBanner,
  SEO,
  ServicesList,
  StartUps,
  SuccessStory,
  Technology,
  Testimonials,
} from '@/page-components';
import type { BlogData } from '@/types/blogResponse';
import type { ClientType } from '@/types/clients';
import type {
  ServiceType,
  TechnologyWorkProcessGetResponseType,
} from '@/types/service';
import type { StartUpType } from '@/types/startup';
import type { SuccessStoriesType } from '@/types/successStories';
import type { TestimonialType } from '@/types/testimonial';
import getImage from '@/utils/getImage';

export async function getServerSideProps() {
  const [
    data,
    clientsData,
    storiesData,
    blogsData,
    testimonialsData,
    startUpData,
    technologiesData,
    servicesData,
  ] = await Promise.all([
    getSeo({ url: 'home-page' }),
    getClients(),
    getAllSuccessStories({
      params: {
        populate: 'story_image',
        'sort[0]': 'publishDate:desc',
        'pagination[start]': 0,
        'pagination[limit]': 3,
      },
      filters: [
        {
          param: 'isForHomepage',
          condition: '$eq',
          value: true,
        },
      ],
    }),
    getPosts({
      params: {
        populate:
          'blog_collection,featured_image,categories,author,author.profilePhoto,',
        'sort[0]': 'publishDate:desc',
        'pagination[start]': 0,
        'pagination[limit]': 3,
      },
      filters: [
        {
          param: '[blog_collection][name]',
          condition: '$eqi',
          value: 'blog',
          withBrackets: false,
        },
        {
          param: 'isForHomepage',
          condition: '$eq',
          value: true,
        },
      ],
    }),
    getTestimonials(),
    getStartUp(),
    getTechnologyExpertise(),
    getAllServices({
      params: {
        populate: 'icon',
      },
    }),
  ]);

  return {
    props: {
      pageData: data.data,
      clients: clientsData.data,
      successStories: storiesData.data,
      blogs: blogsData.data,
      testimonials: testimonialsData.data,
      startUp: startUpData.data,
      technologies: technologiesData.data,
      services: servicesData.data,
    },
  };
}

const Index = ({
  pageData,
  clients,
  successStories,
  blogs,
  testimonials,
  startUp,
  technologies,
  services,
}: {
  pageData: BlogData;
  clients: ClientType[];
  successStories: SuccessStoriesType[];
  blogs: BlogData[];
  testimonials: TestimonialType[];
  startUp: StartUpType;
  technologies: TechnologyWorkProcessGetResponseType['data'];
  services: ServiceType[];
}) => {
  const { handleSubmit, RenderAlert } = useContact();

  return (
    <main>
      <SEO postData={pageData} />
      <HeaderBanner />
      <Clients clients={clients} />
      <ServicesList
        services={services
          .sort((a, b) => a.attributes.order - b.attributes.order)
          .map((service) => {
            return {
              id: service.id,
              title: service.attributes.title,
              subtitle: service.attributes.shortDescription,
              thumb: getImage(service.attributes.icon.data.attributes.url),
              link: service.attributes.url,
              featured: service.attributes.featured,
            };
          })}
      />
      <StartUps startup={startUp} />
      <Achievements
        title="We are proud to have acquired these along the journey."
        achievements={achievements}
      />
      {successStories.length > 0 && (
        <SuccessStory title="Success Stories" successStories={successStories} />
      )}
      {testimonials.length > 0 && <Testimonials testimonials={testimonials} />}

      {technologies.id && technologies.attributes.technologies && (
        <Technology technology={technologies.attributes.technologies} />
      )}

      {blogs.length > 0 && (
        <Blogs blogs={blogs} variant="homepage" title="Our Blog" />
      )}

      <ContactForm onSubmit={handleSubmit} RenderAlert={RenderAlert} />
    </main>
  );
};

export default Index;
